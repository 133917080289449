import React, { useState } from "react"
import tw from "twin.macro"
import skeleton from "../../assets/skeletonHomepage.png"
import Fade from "react-reveal/Fade"
import { useForm } from "react-hook-form"
import { ArrowCircleRight } from "heroicons-react"
import { sendEmail } from "../../utils/email"
import Typewriter from "typewriter-effect"
import { Link } from "gatsby"

const HeroContainer = tw.div`bg-white pb-8 sm:pb-8`

const HomepageHero = () => {
  const { register, handleSubmit } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async data => {
    const emailStatus = await sendEmail(data)

    setSubmitted(emailStatus === 200)
  }

  return (
    <HeroContainer>
      <div className="pt-4 overflow-hidden sm:pt-6 lg:relative lg:py-24">
        <div className="mx-auto px-4 max-w-md sm:px-6 sm:max-w-3xl lg:grid lg:gap-24 lg:grid-cols-2 lg:px-8 lg:max-w-7xl">
          <div>
            <div className="mt-10">
              {/* <div>
                <NewSnippet
                  to="/sign-up-for-beta"
                  headline="Pricelist Beta is launching soon!"
                />
              </div> */}
              <div className="mt-6 sm:max-w-xl">
                <div className="inline text-4xl font-extrabold tracking-normal sm:text-5xl sm:leading-normal">
                  <h1>
                    Capture all your <span className="sr-only">sales</span>
                    <div className="inline">
                      <Typewriter
                        loop={true}
                        onInit={typewriter => {
                          typewriter
                            .typeString("sales")
                            .changeDelay(10000)
                            .pauseFor(5000)
                            .deleteAll()
                            .start()
                        }}
                        options={{
                          strings: [
                            "sales",
                            "revenues",
                            "margins",
                            "opportunities",
                            "efficiencies",
                          ],
                          wrapperClassName:
                            "text-indigo-700 border-b-4 border-indigo-300 ",
                          autoStart: true,
                          loop: true,
                          delay: 75,
                        }}
                      />
                    </div>
                  </h1>
                </div>

                <h2 className="mb-6 mt-6 text-gray-700 text-xl leading-loose">
                  <b>Pricelist</b> helps manufacturers price products optimally
                  by bringing clarity to complexity.
                </h2>

                <div className="flex flex-col space-y-4 lg:flex-row lg:items-baseline lg:space-x-8">
                  <div className="pl-6 pr-4 py-2 text-white font-bold bg-gradient-to-r rounded-full hover:shadow-lg from-red-800 to-red-600 transform hover:-translate-y-0.5 uppercase duration-200">
                    <Link
                      to="/contact-sales"
                      className="flex justify-center space-x-2"
                    >
                      <span>Talk to us</span> <ArrowCircleRight />
                    </Link>
                  </div>
                  <div className="mt-2 text-center sm:mt-0">
                    <Link
                      to="product/why-pricelist"
                      className="hover:no-underline underline"
                    >
                      Learn what Pricelist can do for your company
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:px-6 sm:max-w-3xl">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
              <BackgroundShape />
            </div>
            <div className="relative z-0 transform hover:scale-105 duration-300 sm:mx-auto sm:px-0 sm:max-w-3xl lg:pl-12 lg:max-w-none lg:h-full">
              <Fade>
                <img
                  className="w-full shadow-xl ring-black ring-opacity-5 ring-1 lg:w-auto lg:max-w-none lg:h-full lg:rounded-md"
                  src={skeleton}
                  alt=""
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}

export default HomepageHero

const BackgroundShape = () => (
  <svg
    className="absolute right-1/2 top-8 -mr-3 lg:left-0 lg:m-0"
    width="404"
    height="392"
    fill="none"
    viewBox="0 0 404 392"
  >
    <defs>
      <pattern
        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <rect
          x="0"
          y="0"
          width="4"
          height="4"
          className="text-gray-200"
          fill="currentColor"
        />
      </pattern>
    </defs>
    <rect
      width="404"
      height="392"
      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
    />
  </svg>
)

import React from "react"
import HomepageHero from "./HomepageHero"
import HomepageFeatures from "./HomepageFeatures"
import HomepageTeamDescriptions from "./HomepageTeamDescriptions"
import PartnerLogos from "../../components/PartnerLogos"
import CallToActionPageBottom from "../../components/CallToActionPageBottom"

const Homepage = () => {
  return (
    <>
      <HomepageHero />
      <PartnerLogos />
      <HomepageFeatures />
      <HomepageTeamDescriptions />
      <CallToActionPageBottom />
    </>
  )
}

export default Homepage

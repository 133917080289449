import {
  AnnotationOutline,
  CheckCircleOutline,
  CurrencyDollarOutline,
  ExclamationCircleOutline,
  GlobeAltOutline,
  LightBulbOutline,
  LightningBoltOutline,
  MailOutline,
  ScaleOutline,
} from "heroicons-react"
import React from "react"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import priceInsights from "../../assets/insightsScreenshot.png"
import priceDetail from "../../assets/priceDetail.png"
import { FeatureDescription } from "./FeatureDescription"
import { BackgroundPattern } from "./BackgroundPattern"
import { Link } from "gatsby"

function HomepageFeatures() {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative mx-auto px-4 max-w-xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="absolute left-full hidden transform -translate-x-1/2 -translate-y-1/4 lg:block"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-gray-900 text-3xl font-extrabold tracking-tight leading-8 sm:text-4xl">
            A better way to price your products
          </h2>
          <p className="mt-4 mx-auto max-w-3xl text-center text-gray-500 text-xl">
            Whether you are in marketing, product management, finance,
            analytics, or operations, Pricelist will let you focus on adding
            value. Leave opaque processes in the past and embrace a transparent
            and efficient way to market.
          </p>
        </div>

        <div className="relative mt-12 lg:grid lg:gap-8 lg:grid-cols-2 lg:items-center lg:mt-24">
          <div className="relative">
            <h3 className="text-gray-900 text-2xl font-extrabold tracking-tight sm:text-3xl">
              We take care of the complex aspects of international pricing
            </h3>
            <p className="mt-3 text-gray-500 text-lg">
              Pricelist simplifies and automates your pricing process while
              keeping you completely in control. Replace your lengthy data
              gathering, modelling, and alignment meetings with a fast solution
              and collaborative reviews.
            </p>

            <dl className="mt-10 space-y-10">
              <FeatureDescription
                icon={<CurrencyDollarOutline />}
                heading="Optimal pricing decisions"
                description="Pricelist's algorithms recommend pricing exchange rate
                  scenarios to minimize arbitrage opportunities and need to
                  adjust prices when FX rates move. "
              />
              <FeatureDescription
                icon={<GlobeAltOutline />}
                heading="Up-to-date taxes, duties, and local pricing expectations"
                description="Pricelist automatically factors any taxes and duties into your product prices. 
                  Do you have price elasticity curves? Upload them to understand their implications on volumes."
              />

              <FeatureDescription
                icon={<CheckCircleOutline />}
                heading="Seamless reviews and collaboration"
                description={
                  <>
                    Approve pricing decisions directly in Pricelist or connect
                    them to your preferred project management app. Stop sending
                    mass emails that get lost in approvers' inboxes. See the
                    status in one spot.
                  </>
                }
              />
            </dl>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <BackgroundPattern />
            <Fade right>
              <img
                className="relative mx-auto shadow-md lg:rounded-md"
                width="1000"
                src={priceDetail}
                alt=""
              />
            </Fade>
          </div>
        </div>

        <svg
          className="absolute right-full hidden transform translate-x-1/2 translate-y-12 lg:block"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:gap-8 lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-gray-900 text-2xl font-extrabold tracking-tight sm:text-3xl">
                Automate your market research and respond quickly to competitive
                changes
              </h3>
              <p className="mt-3 text-gray-500 text-lg">
                Keep a finger on the pulse of your market. Did your competitors
                lower their price? Did a government change{" "}
                <attr
                  title="Value Added Tax"
                  className="border-b border-dashed"
                >
                  VAT
                </attr>
                ? Get an automated alert and take action.
              </p>
              <dl className="mt-10 space-y-10">
                <FeatureDescription
                  icon={<LightBulbOutline />}
                  heading="Competitive Pricing Intelligence"
                  description="Pricelist aggregates and analyzes prices from your competitor set. 
                  Get insights into how your competitors set prices across global markets or 
                  how often and how deeply they price promote their products."
                />
                <FeatureDescription
                  icon={<ExclamationCircleOutline />}
                  heading="Smart Alerts for Price, Currency, and Tax Changes"
                  description="Get automated alerts when your competitors 
                    change prices or when exchange rates affect your prices."
                />
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <BackgroundPattern />
              <Fade left>
                <img
                  className="relative mx-auto shadow-md lg:rounded-md"
                  width="400"
                  src={priceInsights}
                  alt=""
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageFeatures

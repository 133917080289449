import React, { useEffect, useState } from "react"
import {
  UserOutline,
  CurrencyDollar,
  ChartBar,
  Check,
  Globe,
  Users,
  UsersOutline,
  CurrencyDollarOutline,
  DeviceMobile,
  DeviceMobileOutline,
  CheckOutline,
  GlobeOutline,
} from "heroicons-react"
import { Link } from "gatsby"
import { teams } from "../../data/teams"
const slug = require("slug")

const HomepageTeamDescriptions = () => {
  const icons = [
    <UsersOutline />,
    <DeviceMobileOutline />,
    <ChartBar />,
    <CurrencyDollarOutline />,
    <CheckOutline />,
    <GlobeOutline />,
  ]

  const TeamDescription = ({ icon, heading, text, children }) => (
    <div>
      <div>
        <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
          <svg
            class="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            {icon}
          </svg>
        </span>
      </div>
      <div class="mt-6">
        <h3 class="text-xl font-medium text-white">{heading}</h3>
        <p class="mt-2 text-base text-purple-200">{children}</p>
      </div>
      <div class="mt-2">
        <Link
          to={`/teams/${slug(heading)}`}
          className=" hover:underline font-semibold text-sm text-yellow-400 hover:text-yellow-500"
        >
          {heading} use cases
        </Link>
      </div>
    </div>
  )

  return (
    <div class="bg-gradient-to-r from-blue-800 to-indigo-800">
      <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 class="text-3xl font-extrabold text-white tracking-tight">
          Pricelist helps every member of your team
        </h2>
        <p class="mt-4 max-w-3xl text-lg text-purple-200"></p>
        <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {teams.map(({ name, icon, description }, idx) => (
            <TeamDescription icon={icons[idx]} heading={name}>
              {description}
            </TeamDescription>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomepageTeamDescriptions

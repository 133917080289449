import React from "react"

const PartnerLogos = ({ hide = true }) => {
  const Logo = ({ alt, src }) => (
    <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
      <img class="h-12" src={src} alt={alt} />
    </div>
  )

  if (hide) return <></>

  return (
    <div class="bg-white">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <Logo
            alt="Typle"
            src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
          />
          <Logo
            alt="Mirage"
            src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg"
          />
          <Logo
            alt="Tuple"
            src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
          />
          <Logo
            alt="Transistor"
            src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
          />
          <Logo
            alt="Workcation"
            src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
          />
        </div>
      </div>
    </div>
  )
}

export default PartnerLogos

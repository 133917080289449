const benefits = [
  {
    heading: "Iterate on pricing faster",
    description:
      "Explore your pricing scenarios instantly as you build your optimal product portfolio.",
  },
  {
    heading: "Know your competition",
    description:
      "Get access to your competitors' global prices and know when and by how much they promote their products.",
  },
  {
    heading: "Manage your P&L",
    description:
      "Set global prices that avoid profit losses from unfavorable currency moves or unnecessary discounting.",
  },
  {
    heading: "Keep the Product Development Process moving",
    description:
      "Centralize the approvals of global prices in one place to maintain visibility into launch progress and stakeholder engagement.",
  },
  {
    heading: "Follow the news",
    description:
      "Keep the finger on the pulse of the press coverage in your target market with automated daily news digest.",
  },
  {
    heading: "Manage the pricing process",
    description:
      "Take ownership of pricing in the go-to-market processes. Break down silos and get stakeholders' approvals with transparent and automated analysis.",
  },
  {
    heading: "Plan your promotions and bundles",
    description:
      "Plan promotions for your products and ensure consistency across markets. Planning a bundle? Estimate its costs and identify levers for controlling it.",
  },
  {
    heading: "Forecast revenues and costs",
    description:
      "Get granular insights into your global revenues and projected promotions and bundle costs.",
  },
  {
    heading: "Implement your SOX controls",
    description:
      "Leverage Pricelist's built-in approvals system to integrate into your existing SOX workflow.",
  },
]

const teams = [
  {
    name: "Product Marketing",
    description:
      "Tailor your pricing to customers' expectations around the world, thanks to Pricelist's knowledge of local expectations.",
    heading:
      "Supercharge your go-to-market plans: focus on impact, not data gathering",
    benefits: [benefits[5], benefits[6], benefits[1]],
  },
  {
    name: "Product Management",
    description:
      "Pricelist gives you control over prices for your products so you can focus on building them, not pricing them.",
    heading: "Build products faster, don't churn on pricing",
    benefits: [benefits[0], benefits[1], benefits[2]],
  },
  {
    name: "Sales",
    description:
      "Quickly determine values of promotions and discounts and ensure consistency across markets.",
    heading: "Spend time selling your products, not chasing their prices",
    benefits: [
      {
        heading: "Know when to promote",
        description:
          "Access your competitors' global prices and know when and by how much they promote their products.",
      },
      {
        heading: "Get feedback on regional pricing faster",
        description:
          "Get feedback on regional prices from your local leaders earlier.",
      },
      {
        heading: "Have a single source of truth",
        description:
          "View your customer, retailer, and distributor prices and keep that knowledge when you onboard and offboard team members.",
      },
    ],
  },
  {
    name: "Finance",
    description:
      "Improve margins and reduce overhead by using our pricing algorithms and transparent approval flows.",
    heading: "Spend time selling, not researching, your products",
    benefits: [
      {
        heading: "Protect and grow your margins",
        description:
          "Use Pricelist to make sure you are not losing profits due to currency movements or changes to taxes and duties.",
      },
      benefits[7],
      benefits[8],
    ],
  },
  {
    name: "Accounting",
    description:
      "Prevent pricing errors and simplify SOX compliance with end-to-end integrations.",
    heading: "Establish rigorous controls with minimal effort",
    benefits: [
      benefits[8],
      benefits[7],
      {
        heading: "Get visibility into contract terms",
        description:
          "Calculate your trade prices without needing to dig through old emails with contract terms.",
      },
    ],
  },
  {
    name: "Business Operations & Strategy",
    description:
      "Compare your product against your competitors' prices and positioning in your target markets.",
    heading: "Don't trade-off strategy and execution, excel at both",
    benefits: [benefits[3], benefits[1], benefits[4]],
  },
]

module.exports = { teams, benefits }
